import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ip-suppression-groups"
    }}>{`IP Suppression Groups`}</h1>
    <p>{`Some users may want to suppress Dragnet alerts if they come from certain IP addresses.  Unlike Notification Groups, an IP Suppression Groups are optional when creating a New Token.`}</p>
    <h2 {...{
      "id": "use-cases"
    }}>{`Use Cases`}</h2>
    <h3 {...{
      "id": "for-email-based-tokens"
    }}>{`For Email Based Tokens`}</h3>
    <p>{`Email-based tokens which are sent to end users might produce a lot of false positives. To reduce false positives, a company could add their public corporate IP address the `}<inlineCode parentName="p">{`IP Suppression Group`}</inlineCode>{`. `}</p>
    <p>{`However, it is important to consider the details of the environment in which the email token is deployed. It is only appropriate if it is your judgement a threat actor is unlikely to access email via the corporate network. For many organizations, the more likely threat vector is web mail.`}</p>
    <h3 {...{
      "id": "for-sensitive-documents"
    }}>{`For Sensitive Documents`}</h3>
    <p>{`Dragnet can be used to set off an alarm if a PDF or Word document is opened outside the corporate network.  This is useful if there are documents you expect to be opened from inside the corporate network only.`}</p>
    <h2 {...{
      "id": "create"
    }}>{`Create`}</h2>
    <p>{`To create an IP Suppression Group, click on `}<inlineCode parentName="p">{`New IP Suppression Group`}</inlineCode>{` on the left menu. Enter a Name, add at least one IP address, and click to `}<inlineCode parentName="p">{`Create IP Suppression Group`}</inlineCode>{`. Dragnet can accept IPv4 and IPv6 addresses. `}</p>
    <h2 {...{
      "id": "edit"
    }}>{`Edit`}</h2>
    <p>{`To edit an `}<inlineCode parentName="p">{`IP Suppression Group`}</inlineCode>{`, navigate from the left menu to the `}<inlineCode parentName="p">{`Edit IP Suppression Group`}</inlineCode>{` interface. When you select the `}<inlineCode parentName="p">{`IP Suppression Group`}</inlineCode>{` you want to edit, the interface presents options to edit the group. It is functionally the same as the interface to create a new group.  Edit the values as necessary and save your changes.`}</p>
    <h2 {...{
      "id": "limitations"
    }}>{`Limitations`}</h2>
    <p>{`We do not recommend using IP Suppression Groups with tokens that use DNS technology (DNS and Windows Folder tokens). This is due to the nature of DNS queries. DNS queries from a computer are sent to a DNS Resolver server, and the DNS Resolver forwards the query to the Dragnet nameservers.  As a result, the IP address Dragnet sees is that of the DNS Resolver, not the originating computer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      